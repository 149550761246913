.UnfulfilledOrdersDashboard {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 25px;
  grid-row-gap: 10px;
  margin-bottom: 35px;

  .container {
    box-sizing: border-box;
    background-color: var(--defcl);
    border: 1px solid rgba(207, 207, 207, 0.114);
    border-radius: 14px;
    min-height: 90px;
    padding: 14px 13px;
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
  }

  p,
  h3 {
    margin: 0;
    color: var(--fntClr);
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.3px;
  }

  h3 {
    font-weight: 700;
    font-size: 28px;
    line-height: 38px;
  }

  .icon {
    width: 42px;
    height: 38px;
    align-self: center;
    border-radius: 8px;
  }

  .approved-orders .icon {
    background-color: #4cbc30;
    > svg {
      transform: scale(0.55) translateX(2px);
    }
  }

  .cancelled-orders .icon {
    background-color: #c23636;
    > svg {
      transform: translate(9px, 6px);
    }
  }

  .callback-orders .icon {
    background-color: #008aff;
    > svg {
      transform: translate(8px, 5px);
    }
  }

  .postponed-orders .icon {
    background-color: #ff8a00;
    > svg {
      transform: translate(8px, 5px);
    }
  }
}

html[dir="rtl"] {
  .UnfulfilledOrdersDashboard {
    .approved-orders .icon > svg {
      transform: scale(0.55) translateX(-2px);
    }

    .cancelled-orders .icon > svg {
      transform: translate(-9px, 6px);
    }

    .callback-orders .icon > svg {
      transform: translate(-8px, 5px);
    }

    .postponed-orders .icon > svg {
      transform: translate(-8px, 5px);
    }
  }
}

@media screen and (max-width: 1080px) {
  .UnfulfilledOrdersDashboard {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 15px;
  }
}

@media screen and (max-width: 550px) {
  .UnfulfilledOrdersDashboard {
    grid-template-columns: 1fr;
  }
}

.ShopSelectFormSearch input:focus + .DataList,
.DataList:hover {
  display: block;
}

.DataList {
  background-color: var(--bdgclInp);
  overflow-x: hidden;
  max-height: 200px;
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  display: none;
  z-index: 100;
}

.DataList-option {
  padding: 5px 10px 0 10px;
  cursor: pointer;
}

.DataList-option:last-child {
  padding: 5px 10px 5px 10px;
}

.DeliveryPricingPage {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;

  .DeliveryPricingOptionSelector {
    background-color: var(--defcl);
    border-radius: 20px;
    padding: 10px;
  }

  .groupPricing {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 54px;
    padding: 0px 10px;
    border-radius: 8px;
    cursor: pointer;

    &:not(:last-child) {
      border-bottom: 1px solid var(--border-bottom-option);
    }
  }
}

.PricingOptionForm-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 50;
}

.PricingOptionForm {
  box-sizing: border-box;
  position: fixed;
  left: 50%;
  top: 50%;
  width: min(100%, 650px);
  height: 90%;
  background-color: var(--defcl);
  border-radius: 15px;
  transform: translate(-50%, -50%);
  z-index: 51;
  padding: 20px 30px;

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .body {
    max-height: 100%;

    .tableField {
      .table {
        border: 1px solid var(--bdgclInp);
        border-radius: 14px;

        .thead {
          background-color: var(--bdgclInp);
          border-radius: 14px 14px 0 0;
          font-weight: bold;
          letter-spacing: 0.3px;
        }
        .tbody {
          overflow-y: scroll;
          height: calc(82vh - 300px);
        }
        .tr {
          display: grid;
          grid-template-columns: 2fr repeat(3, 3fr);
          align-self: center;
          margin: 0px 15px;

          &:not(:last-child) {
            border-bottom: 1px solid var(--bdgclInp);
          }
        }
        .th {
          display: flex;
          align-items: center;
          padding: 11px 10px;
          font-size: 14px;
        }
      }
      input {
        font-size: 14px;
        height: 30px;
        width: 95px;
      }
      .StMrg {
        margin: 0;
      }
    }
  }

  .button-container {
    display: flex;
    justify-content: center;
    margin-top: 17px;
  }
}

@media only screen and (max-width: 550px) {
  .PricingOptionForm {
    padding: 6px 18px;
    height: 93%;
  }
}

@media only screen and (max-width: 800px) {
  .DeliveryPricingPage {
    grid-template-columns: 1fr;
    row-gap: 23px;
  }
}

@mixin buttonStyles($backgroundColor) {
  width: 200px;
  height: 52px;
  background-color: $backgroundColor;
  color: #fff;
  border-radius: 10px;
  border: none;
  font: bold;
  font-size: 18px;
  font-family: Nunito Sans;
  cursor: pointer;
  &:hover {
    background-color: lighten($backgroundColor, 10%);
  }
}
.deleteWebhook {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 627px;
  height: 234px;
  transform: translate(-50%, -50%);
  background-color: #273142;
  color: #fff;
  font-family: "Nunito Sans";
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    align-self: flex-start;
    h1 {
      padding-left: 22px;
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 35px;
      border-radius: 8px;
      border: none;
      margin-right: 17px;
      cursor: pointer;
      &:hover {
        background-color: lighten(#323d4e, 10%);
      }
      background-color: #323d4e;
    }
  }
  main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;

   
      .buttonsContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        button[type="button"] {
          @include buttonStyles(#008aff);
        }
        button[type="submit"] {
          @include buttonStyles(#ff4f4f);
        }
      }
  }
}

@mixin tableBorderRadius($borderRadius) {
  thead tr:first-child {
    th {
      &:first-child {
        border-top-left-radius: $borderRadius;
      }
      &:last-child {
        border-top-right-radius: $borderRadius;
      }
    }
  }
  tr:last-child {
    td {
      &:first-child {
        border-bottom-left-radius: $borderRadius;
      }
      &:last-child {
        border-bottom-right-radius: $borderRadius;
      }
    }
  }
}
.webhooksSettings {
  width: 100%;
  h2 {
    font-size: 32px;
  }
  .userInfo {
    h3 {
      font-size: 25px;
    }
    section {
      h4 {
        font-size: 14px;
        color: #d7d5d5;
      }
      .tokenCopyPasteContainer {
        display: flex;
        align-items: center;
        justify-content: space-around;
        background-color: #323d4e;
        #tokenContainer {
          height: 52px;
          width: 80%;
        }
        button {
          background-color: transparent;
          border: none;
          cursor: pointer;
          font-size: 20px;
        }
      }
    }
  }
  .webhookTable_container {
    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h3 {
        font-size: 25px;
      }
      .addButton {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 52px;
        height: 52px;
        background-color: #323d4e;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        &:hover {
          background-color: lighten(#323d4e, 10%);
        }
      }
    }

    table {
      width: 100%;
      text-align: left;
      th,
      td {
        &:first-child,
        &:last-child {
          padding-right: 31px;
          padding-left: 26px;
        }
      }
      @include tableBorderRadius(10px);
      thead {
        background-color: #323d4e;

        tr {
          th {
            padding-top: 15px;
            padding-bottom: 15px;
            font: bolder;
          }
        }
      }
      tbody {
        background-color: #273142;
        tr {
          td {
            padding-top: 28px;
            padding-bottom: 28px;
            &:first-child {
              color: #008aff;
            }
            .actionsContainer {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 8px 15px;
              max-width: 60px;
              border-radius: 8px;
              background-color: #323d4e;
              button {
                background-color: transparent;
                border: none;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}

.RichTextEditor {
  margin: 25px 0;
  position: relative;

  .tox-tinymce {
    margin-top: 9px;
    border: none;
  }
  &.light .tox-tinymce {
    box-shadow: 0px 10px 15px rgb(0, 0, 0, 0.05);
  }
  &.isError .tox-tinymce {
    border: 1px solid #e90808 !important;
  }
  .tox .tox-statusbar {
    display: none;
  }
  .tox .tox-edit-area__iframe {
    background-color: transparent;
  }
}
.tox-notifications-container {
  display: none;
}

.RichTextEditor svg {
  transform: rotateY(0);
}

.tox .tox-dialog-wrap {
  position: relative;
}

.tox .tox-dialog-wrap:after {
  content: var(--image-progress);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  cursor: progress;
  z-index: 100;
  font-size: 50px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ShopContainer {
  min-height: 400px;
  position: relative;
  --color-success: #1daf4e;

  .vertical-line {
    height: 3px;
    width: 80%;
    margin: 30px auto;
    background-color: var(--bdgclInp);
  }

  .navigation {
    display: flex;
    justify-content: space-evenly;
    padding: 0 20px;

    .NavigationBubble {
      --scale: 1;
      width: 86px;
      display: flex;
      flex-direction: column;
      align-items: center;

      > div {
        aspect-ratio: 1;
        width: 100%;
        border-radius: 1000px;
        background-color: var(--bdgclInp);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        > svg {
          transform: scale(var(--scale));
        }
      }

      &.active > div {
        background-color: #008aff;
      }

      &.done > div {
        background-color: var(--color-success);
      }

      &.disabled > div {
        cursor: not-allowed;
        opacity: 0.5;
      }

      p {
        font-size: 11px;
      }
    }

    .line {
      margin-top: 43px;
      height: 3px;
      width: 3vw;
      background-color: var(--bdgclInp);

      &.active {
        background-color: var(--color-success);
      }
    }
  }

  .StoreSettingsForm {
    .image-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 150px;
      background-color: var(--backgroundGray);
      border: 5px dashed var(--border-dark);
      border-radius: 10px;
      cursor: pointer;

      input {
        display: none;
      }

      > div {
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
          margin-bottom: 0;
        }
      }

      > img {
        object-fit: contain;
        max-width: 100%;
        max-height: 100%;
        height: auto;
        width: auto;
      }
    }

    .StoreSettingsForm-form {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .grp-fm {
        width: 100%;
      }

      button {
        min-width: 200px;
        margin-top: 30px;
      }
    }
  }

  .ThemeSettingsForm {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      text-align: center;
      font-weight: 600;
      font-size: 20px;
    }

    .themes {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px 30px;

      img {
        position: relative;
        border: var(--border-dark) 4px solid;
        border-radius: 5px;
        max-width: 100%;
        cursor: pointer;
        transition: opacity 200ms;
        height: auto;

        &:hover {
          opacity: 0.8;
        }

        &.active {
          border-color: #008aff;
        }
      }
    }

    button {
      min-width: 200px;
      margin-top: 30px;
    }
  }

  .SubdomainSettingsForm,
  .FacebookSettingsForm {
    margin: 60px 30px 0 30px;
    position: relative;

    .new-pixel-control {
      display: flex;
      flex-direction: row-reverse;
      padding: 10px 30px;
    }

    .buttons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 30px;

      > div {
        min-width: 180px;
        cursor: pointer;
        margin: 0 10px;
      }
    }

    .facebook-pixel-container {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      border: 1px solid rgba($color: grey, $alpha: 0.5);
      padding: 20px;

      * {
        margin-bottom: 0px;
      }

      input {
        height: min-content;
        padding: 10px;
      }

      .facebook-pixel-drop-down-root {
        position: relative;
        min-width: 200px;
      }
      .facebook-pixel-status-drop-down {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-end;

        button {
          padding: 10px;
          min-width: 200px;
        }

        .facebook-pixel-actions {
          min-width: 150px;
          background-color: var(--defcl);
          border-radius: 17px;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          z-index: 100000;

          .facebook-pixel-action {
            padding: 17px;
            cursor: pointer;

            &:hover {
              background-color: rgba($color: #000000, $alpha: 0.3);
            }
          }

          .seperator {
            width: 100%;
            height: 1px;
            background-color: rgba($color: #008aff, $alpha: 1);
          }
        }
      }
    }

    .link {
      font-weight: 800;
      color: #008aff;
      margin-left: 3px;
    }
    .subdomain-container {
      position: relative;
      .subdomain-url {
        position: absolute;
        right: 10px;
        bottom: 11px;
        pointer-events: none;
      }
      &:not(:first-child) {
        margin-top: 50px;
      }

      h2 {
        margin: 0;
      }
    }
    .connect-domain-button {
      width: 200px;
    }
  }

  .FacebookSettingsForm {
    min-height: calc(100vh - 484px);
    display: flex;
    flex-direction: column;
    margin-top: 0px;

    .pixel-container:not(:first-child) {
      margin-top: 15px;
    }

    .pixel-container {
      display: flex;
      align-items: center;

      > *:first-child {
        flex-grow: 1;
      }
      > *:last-child {
        margin-left: 20px;
      }
    }

    .buttons {
      justify-content: flex-end;
      align-items: flex-end;
    }

    .link {
      margin-bottom: 10px;
    }

    .StMrg {
      margin-top: 6px;
    }

    label {
      margin-left: 12px;
    }

    .add-button {
      width: fit-content;
      padding: 10px;
      font-weight: 800;
      color: #008aff;
      align-self: flex-end;
      cursor: pointer;
    }

    .delete-button {
      cursor: pointer;
    }
  }

  .FinishSettingShop {
    display: flex;
    flex-direction: column;
    align-items: center;

    .main {
      background-color: var(--bdgclInp);
      width: 555px;
      display: grid;
      grid-template-columns: 1fr 2fr;
      border-radius: 3px;
      padding-bottom: 20px;

      img {
        --logo-spacing: 32px;
        max-width: calc(100% - (var(--logo-spacing) * 2));
        margin: 22px var(--logo-spacing);
        border-radius: 1000px;
      }

      .SubElement {
        margin-top: 20px;
        p {
          margin: 0;
        }

        .value {
          font-weight: 800;
          font-size: 20px;
        }
      }
    }

    .publish button {
      margin-top: 30px;
      width: 256px;
    }
  }
}

.LandingPageContainer {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-column-gap: 40px;

  * {
    margin: 0;
  }
  .landingPage-container {
    background-color: var(--defcl);
    border-radius: 20px;
    margin: 20px 0;
    padding: 10px;
  }
  .links {
    .link {
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 54px;
      border-bottom: 1px solid #313d4f;
      padding-inline-start: 10px;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      &.active {
        background-color: #008aff;
      }
      .arrow {
        transform: scale(1.3);
        display: flex;
      }
    }
  }
}

.LandingPageHeader,
.LandingPageFooter,
.LandingPageCongratulation {
  position: relative;
  min-height: 161px;
  padding-bottom: 90px;

  .button {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

html[dir="rtl"] {
  .SubdomainSettingsForm input {
    text-align: left;
  }

  .LandingPageHeader,
  .LandingPageFooter,
  .LandingPageCongratulation {
    .button {
      position: absolute;
      right: auto;
      left: 0;
    }
  }
}

@media only screen and (max-width: 920px) {
  .responsive-computer {
    display: none !important;
  }

  .FlHg.StVlWdt.ClSidTh.StBxSh.stbd2.OvfAtoY.StBgbrd.HdOvFlwX.StMerV.StPs.StTpNl {
    position: static;
    margin-bottom: 50px;
  }

  .ShopContainer {
    margin-bottom: 50px;

    .navigation {
      flex-direction: column;
      padding: 0;

      .NavigationBubble {
        flex-direction: row;
        width: 100%;
        margin: 15px 0px;
        > div {
          width: 60px;
        }

        > p {
          margin-left: 10px;
          font-size: 18px;
        }
      }

      .line {
        margin: 0px 29px;
        height: 17px;
        width: 3px;
      }
    }

    .children {
      margin: 15px 0;
    }

    .ThemeSettingsForm {
      > p {
        margin-top: 0;
      }
      .themes {
        grid-template-columns: 1fr;
      }
    }

    .SubdomainSettingsForm,
    .FacebookSettingsForm {
      margin: 0px;

      .subdomain-url {
        display: none;
      }
      .buttons {
        flex-direction: column;

        > div:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }

    .FinishSettingShop .main {
      max-width: 100%;

      img {
        max-width: 44px;
        margin: 10px;
      }

      .SubElement .title {
        font-size: 12px;
      }

      .SubElement .value {
        font-size: 14px;
      }
    }
  }
  .ShopPublishedSuccess svg {
    height: auto !important;
  }

  .LandingPageContainer {
    display: block;
  }

  .ShopContainer .FacebookSettingsForm .buttons {
    align-items: center;
  }
}

@media only screen and (min-width: 921px) {
  .responsive-mobile {
    display: none !important;
  }
}

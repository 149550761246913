.notif {
    width: 300px;
    .notif_container {
        padding-left: 10px;
        padding-right: 10px;
    .notif_body {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }
}

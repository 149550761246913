.modal__content{
  margin-top: 10px; 
  margin-bottom: 10px; 
  display: flex; 
  flex-direction: column; 
  align-items: flex-start;
}

.modal__header{
  margin-bottom: 20px;
}

.welcome__message{
  text-align: center; 
  font-size: 24px;
  width: 100%;
  
  margin-bottom: 12px;
}

.secondary__welcome__message{
  font-size: 16px;
  margin: 0px auto;
  padding: 0 30px;
  margin-bottom: 15px;
}

.services__grid{
  max-width: 100%;
  margin: 20px auto;
  padding: 0 30px; 
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 12px;
  list-style: none;
}

.services__Item{
  padding: 6px 16px; 
  display: flex; 
  align-items: center;
  color: white;
  border-radius: 333px;
  justify-content: center;
  text-align: center;
  border: 1px solid var(--primary, #008AFF);
  background: #3E516E;
  font-weight: 700;
  font-size: 16px;
}

.contact__us__container{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: calc(100% - 80px);
  margin: 0 auto;
  gap: 24px; 
}

.divider{
  height: 1px;
  width: calc(100% - 80px);
  margin: 0 auto;
  background-color: #546989;
}

.contact__us__col{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  text-align: start;
  padding: 15px 0;
  font-size: 16px;
}

.contact__item{
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-start;
}

.social__media{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}


@media screen and (max-width: 500px) {
  .welcome__message{
    font-size: 20px;
  }

  .secondary__welcome__message{
    font-size: 16px;
    margin: 8px auto;
  }

  .services__grid{
    margin: 10px auto;
    gap: 5px;
  }

  .services__Item{
    font-size: 16px;
  }

  .contact__us__message{
    font-size: 16px;
  }
  
  .contact__us__container{
    flex-direction: column;
    align-items: center;
  }

}
.addWebhook {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  height: 500px;
  transform: translate(-50%, -50%);
  background-color: var(--defcl);
  color: var(--fntClr);
  font-family: "Nunito Sans";

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    align-self: flex-start;
    h1 {
      padding-left: 22px;
      color: var(--fntClr);
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 35px;
      border-radius: 8px;
      border: none;
      margin-right: 17px;
      cursor: pointer;
      &:hover {
        background-color: lighten(#ff4f4f, 10%);
      }
      background-color: #ff4f4f;
    }
  }
  main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 80%;
      section {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 20px;
        input,
        select {
          padding: 18px 0px 15px 16px;
          margin-top: 11px;
          background: var(--bdgclInp) 0% 0% no-repeat padding-box;
          border: 1px solid var(--clrbrd);
          font-family: var(--fontLan);
          color: var(--fntClr);
          width: auto;
        }
      }
      button[type="submit"] {
        width: 200px;
        height: 52px;
        background-color: #008aff;
        font-family: var(--fontLan);
        color: var(--fntClr);
        border-radius: 10px;
        border: none;
        font: bold;
        font-size: 18px;
        font-family: Nunito Sans;
        cursor: pointer;
        &:hover {
          background-color: lighten(#008aff, 10%);
        }
      }
    }
  }
}

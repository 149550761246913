.UnfulfilledOrderDetails {
  .delivery-status {
    svg {
      transform: scale(1.3);
    }
    tbody td > div {
      align-items: center;
    }
  }
}

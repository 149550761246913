.LandingPageColors {
  position: relative;
  min-height: 161px;
  min-height: 249.2px;

  .button {
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .sketch-picker input {
    color: black;
  }
  .color-container {
    height: 31px;
    margin: 20px 0;
    display: flex;
    align-items: center;
    position: relative;
  }
  .color-box {
    width: 51px;
    height: 31px;
    border-radius: 4px;
    cursor: pointer;
    margin-inline-end: 10px;
    border: 1px solid black;
  }
  .color-title {
    width: 208px;
  }
  .color-gif {
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    opacity: 0;
    transition: opacity 200ms;
    max-width: 300px;
    z-index: 2;
    pointer-events: none;
    overflow: hidden;

    img {
      width: 100%;
    }
  }
  .color-container:hover .color-gif {
    opacity: 1;
  }
  .reset-colors-button {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
  .reset-color-fill {
    fill: var(--reset-color-fill);
  }
}

html[dir="rtl"] {
  .LandingPageColors {
    .button {
      position: absolute;
      right: auto;
      left: 0;
    }
  }
}

.LandingPageList {
  .ShopProductStatus {
    width: 104px;
    padding: 5px 0px;
    text-align: center;
    border-radius: 8px;
    color: white;

    &.active {
      background-color: #1daf4e;
    }

    &.inactive {
      background-color: #e74c3c;
    }
  }
}

@media only screen and (max-width: 564px) {
  .LandingPageList .Stwd-stck.Rgl-wdtb.frCl-ss2 td:first-child {
    max-width: 100px;
  }
}

.BrgSVGTB {
  position: relative;
  width: 130px;
  height: 130px;
}
.BrgSVGTB circle {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: var(--gcl);
  stroke-width: 13;
  stroke-linecap: round;
  transform: translate(5px, 5px);
}
.BrgSVGTB circle:nth-child(2) {
  stroke-dasharray: 440;
  stroke-dashoffset: calc(440 - (var(--PrcBl) * 350) / 100);
  stroke: #008aff;
}
.Brcnelm {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

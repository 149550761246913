.PostponeDatePicker {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 16;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    margin: 0;
  }
}

.PostponeDatePicker .shadow {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 17;
  cursor: pointer;
}

.PostponeDatePicker .container {
  position: absolute;
  height: min-content;
  width: min-content;
  top: 50%;
  left: 50%;
  z-index: 18;
  transform: translate(-50%, -50%);
  background-color: var(--date-picker-bg);
  padding: 15px 28px;
  border-radius: 14px;
  text-align: center;
}

.PostponeDatePicker .buttons {
  font-size: 15px;

  button {
    color: white;
    font-weight: 600;
    border: 1px solid #008aff;
    padding: 10px 14px;
    border-radius: 8px;
    margin: 0 10px;
    cursor: pointer;
  }

  .cancel {
    background: none;
    color: var(--fntClr);
  }

  .confirm {
    background: #008aff;

    &.disabled {
      cursor: not-allowed;
      opacity: 0.7;
    }
  }
}

@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@400;600;800&family=Poppins:wght@400;600;800&display=swap");

html.preview {
  font-size: 62.5%;
  scroll-behavior: smooth;
  scroll-padding-top: 180px;
}

html.preview,
html.preview body {
  width: 100%;
  min-height: 100vh;
}

html.preview[dir="rtl"] svg {
  transform: rotateY(0);
}

html.preview body {
  --box_bg: #008aff;
  --font_color: #ffffff;
  --text_color: #000000;
  --primary_color: #e9230f;
  --button_bg: #008aff;
  --btn_font_color: #fff;
  --box_footer: #f2f2f2;
  --color-bg-1: white;
  --color-placeholder: #777777;
  --color-bg-cancel: transparent;
  --border-cancel: solid 1px #808080;
  --color-border-2: rgba(151, 151, 151, 0.2);
  --color-danger: #ff4f4f;
  --space-body: 15rem;
  --main-padding: 6rem;
  --main-max-width: 114rem;
  --spacing-items: 1rem;
  --input_bg: #F3F3F3;
  --summary_bg: var(--button_bg);
    font-size: 1.6rem;
    background-color: var(--color-bg-1);
}

html.preview #root * {
  font-family: "Poppins", sans-serif;
}

html.preview[theme="dark"] body {
  --box_bg: #008aff;
  --font_color: #ffffff;
  --text_color: #ffffff;
  --primary_color: #e85242;
  --box_footer: #273142;
  --color-bg-1: #1b2431;
  --color-bg-cancel: #455164;
  --color-placeholder: #989797;
  --border-cancel: solid 1px transparent;
  --input_bg: #323D4E;
  --summary_bg: var(--input_bg);
}

::-webkit-scrollbar {
  width: 0.7em;
  height: 0.7em;
}

::-webkit-scrollbar-track {
  background: #bfbfbf;
}

::-webkit-scrollbar-thumb {
  background: #4d4d4d;
}

@supports (scrollbar-color: #4d4d4d #bfbfbf) {
  * {
    scrollbar-color: #4d4d4d #bfbfbf;
    scrollbar-width: thin;
  }
}

html.preview a,
html.preview a:visited,
html.preview a:link {
  text-decoration: inherit;
  color: inherit;
}

html.preview * {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  list-style: none;
  color: var(--text_color);
}

html.preview nav {
  background-color: var(--box_bg);
  height: 7rem;
  padding-right: max(
    var(--main-padding),
    calc((100vw - var(--main-max-width)) / 2)
  );
  padding-left: max(
    var(--main-padding),
    calc((100vw - var(--main-max-width)) / 2)
  );
  display: grid;
  grid-template-columns: 5.7rem 1fr 5.7rem;
}

html.preview nav .logo {
  height: 5.7rem;
  width: 5.7rem;
  border-radius: 1000px;
  object-fit: contain;
}

html.preview nav div {
  display: flex;
  justify-content: center;
  align-items: center;
}

html.preview nav p {
  text-align: center;
  display: inline-block;
  padding: 0 2rem;
  color: var(--font_color);
}

.fillTextSvg {
  fill: var(--font_color);
}

.fillBgSvg {
  fill: var(--box_bg);
}

html.preview .main {
  max-width: var(--main-max-width);
  margin: 6rem auto;
  padding: 0 var(--main-padding);
  border-radius: 14px;
}

.fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: var(--color-bg-1);
  height: 6.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, 0.1);
  transform: translateY(6.5rem);
  transition: all 400ms;
  z-index: 3;
}

.fixed.show {
  transform: translateY(0rem);
}

.fixed .button.buy-button {
  margin: 0;
}

html.preview footer {
  background-color: var(--box_bg);
  width: 100%;
  height: 13.8rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-bottom: 6.5rem;
}

footer .badge {
  height: 5rem;
}

footer .badge img {
  transform: translateY(7px);
}

.social {
  width: 24rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.social .facebook,
.social .instagram,
.social .tiktok {
  margin: 0 auto;
}

html.preview footer svg {
  fill: var(--font_color);
}

.button.buy-button[disabled] {
  opacity: 50%;
  cursor: not-allowed;
  transition: all 200ms;
  animation: 0 !important;
}

.main .product {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.main .product .slide-images {
  margin-right: 3rem;
  user-select: none;
}

.main .product .slide-images .display-image {
  position: relative;
  aspect-ratio: 1;
}

.main .product .slide-images .display-image img {
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
}

.main .product .slide-images .display-image img.active {
  z-index: 1;
  opacity: 1;
}

.main .product .slide-images .display-image .previous,
.main .product .slide-images .display-image .next {
  position: absolute;
  top: 50%;
  height: 5rem;
  width: 5rem;
  padding: 1rem;
  background-color: white;
  border-radius: 1000px;
  transform: translatey(-50%);
  cursor: pointer;
  z-index: 2;
}

.main .product .slide-images .display-image .previous {
  left: 1rem;
}

.main .product .slide-images .display-image .next {
  right: 1rem;
}

.main .product .slide-images .previews {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}

.main .product .slide-images .previews .preview {
  max-width: 6rem;
  border-radius: 5px;
  cursor: pointer;
  aspect-ratio: 1;
}

.main .product .slide-images .previews .preview:not(:first-child) {
  margin-left: 1.5rem;
}

.main .product .product-name,
.main .product .product-quantity {
  font-size: 3.5rem;
  font-weight: 800;
}

.main .product .product-details.mobile {
  display: none;
}

.main .product .product-details .product-price {
  margin-left: calc(var(--spacing-items) / 2);
  color: var(--primary_color);
  font-size: 3.5rem;
  font-weight: 800;
}

.main .product .product-price-container {
  display: flex;
  align-items: center;
  margin-top: calc(var(--spacing-items) * 2);
}

.main .product .product-details .product-price-crossed {
  margin-left: var(--spacing-items);
  margin-top: calc(var(--spacing-items) / 2);
  font-weight: 400;
  font-size: 2.8rem;
  text-decoration: line-through;
}

.main .product .product-details .product-price-crossed.hidden {
  display: none;
}

.main .product .product-details .review {
  margin-left: calc(var(--spacing-items) / 2);
  display: flex;
}

.main .product .product-details .review .rate {
  display: none;
}

.stars {
  display: flex;
}

.stars li {
  margin: 0 0.2rem;
}

.stars li .star,
.accordion {
  height: 2rem;
  width: 2rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: transparent;
}

.product .form {
  display: grid;
  margin-left: calc(var(--spacing-items) / 2);
  grid-gap: var(--spacing-items) calc(var(--spacing-items) * 2);
  grid-template-columns: repeat(2, calc(50% - var(--spacing-items)));
}

.form > * {
  display: flex;
  flex-direction: column;
}

.form label {
  font-size: 1.6rem;
}

.form input,
.form select {
  margin-top: calc(var(--spacing-items) * 0.7);
  border: none;
  background-color: var(--input_bg);
  border: solid 1px var(--button_bg);
  height: 5.2rem;
  border-radius: 4px;
  padding: 0 1.3rem;
  font-size: 1.8rem;
}

.form select {
  color: var(--color-placeholder);
}

html.preview ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color-placeholder);
  opacity: 1; /* Firefox */
}

html.preview :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--color-placeholder);
}

html.preview ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--color-placeholder);
}

html.preview select {
  cursor: pointer;
}

.form .options {
  grid-column: 1 / 3;
  font-size: 1.8rem;
}

.form .options .price {
  font-weight: 700;
}

.form .quantity {
  flex-direction: row;
  align-items: center;
  grid-column: 1 / 3;
  margin-top: calc(var(--spacing-items) * 0.7);
}

.form .quantity input {
  margin: 0 2rem;
  max-width: 13rem;
}

.form .options > div {
  display: flex;
  align-items: center;
  font-weight: 400;
  max-height: 3.5rem;
}

.form .options > div input {
  margin-top: 0;
  height: 1.5rem;
  width: min-content;
  margin-right: var(--spacing-items);
  transform: scale(1.3);
  cursor: pointer;
}

.form .options label {
  color: var(--text_color);
  min-height: 48px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.form .options label span {
  margin-left: 0.4rem;
}

.form .options .price {
  color: var(--primary_color);
}

.form .options .price-crossed {
  text-decoration: line-through;
  font-size: 1.4rem;
}

html.preview .button {
  margin-top: calc(var(--spacing-items) * 0.7);
  border: none;
  border-radius: 12px;
  padding: 0.7rem 0rem;
  font-weight: 600;
  font-size: 1.8rem;
  cursor: pointer;
  color: var(--btn_font_color);
}

.fixed .buy-button{
  min-width: 46rem;
}

#bundle-form .button {
  padding: 1.4rem 4.5rem;
}

.buy-button {
  margin-top: var(--spacing-items);
  background-color: var(--button_bg);
}

.cancel-button {
  background-color: var(--color-bg-cancel);
  border: var(--border-cancel);
  color: var(--text_color);
}

html.preview .button svg {
  max-width: 1.9rem;
  fill: var(--btn_font_color);
  margin: 0 0.8rem;
}

html.preview .form .button {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-column: 1 / span 2;
}

.order-summary {
  grid-column: 1 / span 2;
  margin-top: var(--spacing-items);
  border-radius: 8px;
  position: relative; 
  overflow: hidden;
}

.order-summary::before {
  content: ""; 
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--summary_bg); 
  opacity: 0.1; 
  z-index: -1; 
}

html.preview[theme="dark"] .order-summary::before {
  opacity: 1;
}

.order-summary .head,
.order-summary .body {
  padding: 11px 10px;
}

.order-summary .head,
.order-summary .total_amount {
  font-weight: 700;
  font-size: 1.9rem;
}

.order-summary .total_amount span:first-child {
  font-weight: 600 !important;
}

.order-summary .total_amount span:last-child {
  color: var(--primary_color);
}

.order-summary .body {
  font-size: 1.8rem;
  padding-top: 0 !important;
}

.order-summary.hidden .body {
  display: none;
}

.order-summary .accordion {
  background-image: url("/assets/landing-page/svg/hide.svg");
  cursor: pointer;
}

.order-summary.hidden .accordion {
  background-image: url("/assets/landing-page/svg/open.svg");
}

html.preview[theme="dark"] .order-summary .accordion {
  background-image: url("/assets/landing-page/svg/hide-dark.svg");
}

html.preview[theme="dark"] .order-summary.hidden .accordion {
  background-image: url("/assets/landing-page/svg/open-dark.svg");
}

.order-summary .head,
.order-summary .body > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 3rem;
}

html.preview .title {
  font-size: 3.3rem;
  font-weight: 700;
  margin-top: calc(var(--spacing-items) * 2);
  margin-bottom: var(--spacing-items);
}

html.preview .title.big {
  font-size: 3.6rem;
}

.bundle .container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3rem;
}

.bundle .container > span {
  margin: 0 calc(var(--spacing-items) * 3);
  margin-top: 6rem;
  font-size: 3.2rem;
  font-weight: 600;
}

.bundle .container .bundle-product {
  max-width: 16rem;
}

.bundle .container .bundle-product .product-name {
  font-size: 1.8rem;
  font-weight: 600;
}

.bundle .container .product-image {
  max-width: 100%;
  aspect-ratio: 1;
  border-radius: 5px;
  height: auto;
}

.bundle .container .price {
  margin-left: calc(var(--spacing-items) * 5);
  font-size: 2.6rem;
  font-weight: 600;
}

.bundle .container .price span {
  color: var(--primary_color);
}

.bundle .container .price span.crossed {
  color: var(--text_color);
  text-decoration: line-through;
}

.bundle .container .price .container-promo {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.bundle-order-popup {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 4;
  display: none;
}

.bundle-order-popup.active {
  display: block;
}

.bundle-order-popup .container {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  background-color: var(--color-bg-1);
  border-radius: 14px;
  min-width: 75vw;
  max-width: 100%;
}

.bundle-order-popup .container .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 6.3rem;
  padding: 0 2.4rem;
  width: 100%;
}

.bundle-order-popup .container .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 6.3rem;
  padding: 0 2.4rem;
  width: 100%;
}

.bundle-order-popup .buttons {
  grid-column-start: 1;
  grid-column-end: 3;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 3rem;
}

.bundle-order-popup .buttons .button {
  font-size: 1.4rem;
  min-width: 0;
}

.bundle-order-popup .buttons .button:first-child {
  margin-right: 1rem;
}

.bundle-order-popup .container .header h4 {
  font-size: 1.8rem;
}

.bundle-order-popup .container .header button {
  background-color: var(--box_bg);
  border: none;
  border-radius: 8px;
  width: 3.5rem;
  height: 3.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bundle-order-popup .container .header button svg {
  fill: var(--btn_font_color);
  max-width: 1.2rem;
}

.bundle-order-popup .container #bundle-form {
  display: grid;
  margin-left: calc(var(--spacing-items) / 2);
  margin-top: calc(var(--spacing-items) * 1.5);
  grid-gap: var(--spacing-items) calc(var(--spacing-items) * 2);
  grid-template-columns: calc(45% - var(--spacing-items)) calc(
      55% - var(--spacing-items)
    );
  padding: 2rem 4.9rem 4.5rem 4.9rem;
  border-top: 1px solid var(--color-border-2);
}

.bundle-order-popup .form {
  margin-bottom: calc(var(--spacing-items) * 1.5);
}

.bundle-order-popup .form > label,
.bundle-order-popup .form > input {
  margin-bottom: var(--spacing-items);
}

.more-products .list {
  display: flex;
  overflow-x: auto;
  overflow-wrap: normal;
  padding-bottom: calc(var(--spacing-items) * 2);
}

.more-products .list .item {
  width: 16rem;
}

.more-products .list .item:not(:first-child) {
  margin-left: calc(var(--spacing-items) * 3);
}

.more-products .list .item img {
  width: 16rem;
  aspect-ratio: 1;
  border-radius: 5px;
}

.more-products .list .item .price {
  color: var(--primary_color);
  font-size: 1.5rem;
  font-weight: 800;
}

.more-products .list .item .name {
  font-size: 1.8rem;
  font-weight: 600;
}

.success {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.success .logo {
  max-width: 24rem;
  aspect-ratio: 1;
  border-radius: 1000px;
}

.success .title {
  color: var(--primary_color);
  font-size: 6rem;
  font-weight: 600;
  margin: 0;
}

.success .text {
  font-size: 3rem;
  font-weight: 400;
}

.error-message {
  position: fixed;
  left: 1rem;
  top: 100vh;
  background-color: var(--color-danger);
  z-index: 5;
  padding: 1rem;
  padding-right: 3rem;
  max-width: 30rem;
  border-radius: 2px;
  box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.5);
  transform: translateY(0%);
}
.error-message p.message{
  color: white;
}

.error-message.active {
  animation-name: ErrorPop;
  animation-duration: 7s;
  animation-iteration-count: 1;
}

@keyframes ErrorPop {
  0% {
    transform: translateY(0%);
  }

  5% {
    transform: translateY(-110%);
  }

  95% {
    transform: translateY(-110%);
  }

  100% {
    transform: translateY(0%);
  }
}

.loader-spinner {
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 18px;
  height: 18px;
  animation: spin 2s linear infinite;
  margin: 0 auto;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.error-message .close-error {
  background-color: transparent;
  font-size: 2rem;
  cursor: pointer;
  border: none;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

.error-message .close-error svg {
  fill: #fff;
  width: 1rem;
}

.product-details p *,
.section * {
  max-width: 100% !important;
  height: auto;
}

.main .product-details:hover .wobble {
  animation: wobble 3s ease infinite;
}

.fixed .wobble {
  animation: wobble-intense 3s ease infinite;
}

html.preview[dir="rtl"] #root * {
  font-family: "Cairo", sans-serif;
}

html.preview[dir="rtl"] .main .product .slide-images {
  margin-right: 0;
  margin-left: 3rem;
}

html.preview[dir="rtl"] .form,
html.preview[dir="rtl"] .main .product .product-details .review,
html.preview[dir="rtl"] .main .product .product-details .product-price,
html.preview[dir="rtl"] .main .product .product-details .product-price-crossed {
  margin-right: var(--spacing-items);
  margin-left: 0;
}

html.preview[dir="rtl"]
  .main
  .product
  .slide-images
  .previews
  .preview:not(:first-child) {
  margin-left: 0;
  margin-right: 1.5rem;
}

html.preview[dir="rtl"] .form .options div input {
  margin-left: var(--spacing-items);
  margin-right: 0;
}

html.preview[dir="rtl"] .more-products .list .item:not(:first-child) {
  margin-right: calc(var(--spacing-items) * 3);
  margin-left: 0;
}

html.preview[dir="rtl"] .bundle .container .price {
  margin-right: calc(var(--spacing-items) * 5);
  margin-left: 0;
}

html.preview[dir="rtl"] .main .product .slide-images .display-image .previous,
html.preview[dir="rtl"] .main .product .slide-images .display-image .next {
  left: 1rem;
  right: auto;
  transform: rotateY(180deg) translatey(-50%);
}

html.preview[dir="rtl"] .main .product .slide-images .display-image .previous {
  right: 1rem;
  left: auto;
}

html.preview[dir="rtl"] .form .options label span {
  margin-right: 0.4rem;
  margin-left: 0;
}

html.preview[dir="rtl"] .bundle-order-popup .buttons .button:first-child {
  margin-left: 1rem;
}

html.preview[dir="rtl"] .stars li .star {
  transform: rotateY(180deg);
}

html.preview[dir="rtl"] .button svg {
  margin-right: 0rem;
  margin-left: 0.8rem;
}

@media only screen and (max-width: 1140px) {
  nav .logo {
    margin-left: 1rem;
  }

  .main {
    border-radius: 0;
  }

  .product .form {
    margin: 0 !important;
  }
}

@media only screen and (max-width: 1130px) {
  html.preview body {
    --main-padding: 3rem;
  }

  html.preview nav {
    grid-template-columns: 5.7rem 1fr;
  }

  .main {
    margin: 0 auto;
  }

  .main .product {
    display: block;
  }

  .product .form {
    margin-left: 0;
    margin-right: 0 !important;
  }

  .main .product .slide-images,
  html.preview[dir="rtl"] .main .product .slide-images {
    margin-right: 0;
    margin-left: 0;
  }

  .main .product .product-details.mobile {
    display: block;
  }

  .main .product .product-details:not(.mobile) > *:not(.form) {
    display: none;
  }

  .main {
    padding-top: calc(var(--main-padding) / 2);
  }

  .fixed .buy-button {
    min-width: 90vw;
  }

  .fixed .wobble {
    animation: wobble 3s ease infinite;
  }

  .bundle-order-popup .container {
    transform: translate(-50%, -50%) scale(0.65);
    min-width: 115vw;
  }

  .button {
    min-width: 0;
    max-width: 94vw;
  }
}

@media only screen and (max-width: 1010px) {
  .bundle .container .price {
    margin-left: 0;
    margin-top: calc(var(--spacing-items) * 2);
    font-size: min(2.5rem, 5.35vw);
  }

  .bundle .container .price > div {
    width: fit-content;
  }

  .bundle .container > span {
    margin: 6rem 2px 0 2px;
  }

  .bundle .container .bundle-product {
    max-width: calc((100vw - 50px - (var(--main-padding) * 2 / 1.5)) / 3);
  }

  html.preview[dir="rtl"] .bundle .container .price {
    margin-right: 0;
  }
}

@media only screen and (max-width: 890px) {
  .bundle .container .price,
  .bundle .price .buy-button {
    width: 100%;
  }
}

@media only screen and (max-width: 610px) {
  html.preview nav p {
    font-size: 1.1rem;
  }
}

@media only screen and (max-width: 590px) {
  .main {
    padding-left: calc(var(--main-padding) / 1.5);
    padding-right: calc(var(--main-padding) / 1.5);
    padding-top: calc(var(--main-padding) / 2);
  }

  .bundle .container > span {
    margin-top: 8vw;
  }

  .bundle-order-popup .container .body {
    padding: 2rem 2rem 4.5rem 2rem;
  }

  .bundle-order-popup .container #bundle-form {
    display: block;
  }
}

@media only screen and (max-width: 560px) {
  .form {
    display: flex;
    flex-direction: column;
    max-width: 100%;
  }

  .main .product .slide-images .previews {
    overflow-x: auto;
    padding-bottom: calc(var(--spacing-items) * 2);
    justify-content: flex-start;
  }
}

@keyframes wobble {
  0% {
    transform: translateX(0%);
  }
  7.5% {
    transform: translateX(-1%) rotate(-0.6deg);
  }
  15% {
    transform: translateX(1%) rotate(0.3deg);
  }
  22.5% {
    transform: translateX(-0.5%) rotate(-0.3deg);
  }
  30% {
    transform: translateX(0.25%) rotate(0.1deg);
  }
  37.5% {
    transform: translateX(-0.125%) rotate(-0.1deg);
  }
  45% {
    transform: translateX(0%);
  }
}

@keyframes wobble-intense {
  0% {
    transform: translateX(0%);
  }
  7.5% {
    transform: translateX(-2%) rotate(-1.2deg);
  }
  15% {
    transform: translateX(2%) rotate(0.6deg);
  }
  22.5% {
    transform: translateX(-1%) rotate(-0.6deg);
  }
  30% {
    transform: translateX(0.5%) rotate(0.2deg);
  }
  37.5% {
    transform: translateX(-0.25%) rotate(-0.2deg);
  }
  45% {
    transform: translateX(0%);
  }
}


/* ======== VARIANTS ========== */

.variants-section{
  margin: 2.5rem 0 !important;
  margin-left: 10px !important;
}

html.preview[dir="rtl"] .variants-section{
  margin-right: 10px !important;
}

.selected-variants{
  margin-bottom: 20px !important;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.selected-variants li{
  border-radius: 4px;
  border: 1px solid var(--primary-light, #008AFF);
  background-color: #D8EDFF;
  padding: 3px 3px 3px 8px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 3px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  color: #008AFF;
}

html.preview[dir="rtl"] .selected-variants li{
  flex-direction: row-reverse;
}
  
.selected-variants li span{
  color: #008AFF;
}

.selected-variants li button{
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-left: 5px;
}

.variants-selector-container{
    display: flex;
    align-items: center;
    gap: 5px;
    border: #DDDDDD solid 1px;
    border-radius: 4px;
    position: relative;
}

html.preview[theme="dark"] .variants-selector-container{
  border: none;
}

.variants-selector-container svg{
  position: absolute;
  right: 10px;
}

html.preview[dir="rtl"] .variants-selector-container svg{
  left: 10px;
  right: auto;
  position: absolute;
}

.variants-selection{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin: 25px 0;
}

.variants-selection select{
  padding: 15px 15px 15px 5px;
  border-color: #DDDDDD;
  font-size: 14px;
  outline: 0;
  background-color: transparent;
}

.variants-selector{
  flex: 1;
}

.variants-selector:after {
  right: 11px;  
  bottom: 13px;
  padding: 0 0 2px;
  position: absolute;
  pointer-events: none;
  width: 100%;
}

.variants-selector select{
  min-width: 300px;
  border-radius: 4px;
  width: 100%;
  border-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

html.preview[theme="dark"] .variants-selector select{
  background-color: var(--input_bg);
  border: none;
}

.variants-selector select option{
  padding-bottom: 20px !important;
}

.variants-section label, .variants-section p{
  display: block;
  margin-bottom: 10px;
  font-size: 1.8rem;
}

.variants-quantity-selector{
  display: flex;
  justify-content: space-between;
  border: solid 1px #DDDDDD;
  padding: 8px 5px !important;
  border-radius: 4px;
  gap: 3px;
}

html.preview[dir="rtl"] .variants-quantity-selector{
  padding: 5px 7px !important;
} 

html.preview[theme="dark"] .variants-quantity-selector{
  background-color: var(--input_bg);
  border: none;
}

.variants-quantity-selector input{
  justify-self: center;
  align-self: center;
  margin-left: 10px;
  border: none;
  outline: none;
  max-width: 15px;
}

html.preview[dir="rtl"] .variants-quantity-selector input{
  margin-right: 7px;

}

.variants-quantity-btns{
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(3px);
}

.variants-quantity-btns button{
  display: block;
  background:none;
  outline: none;
  border: none;
  cursor: pointer;
}

.variants-quantity-btns button:first-child svg{
  transform: rotate(180deg);
}

.variants-section .variants-btn{
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  align-self:last baseline;
  margin-bottom: 15px;
  background-color: var(--button_bg);
  background-position: center;
  background-repeat: no-repeat;
  background-size:auto;
  cursor: pointer;
  border: none;
  padding: 10px 0;
}

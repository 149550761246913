.ShopSettingMain {
  .links {
    margin-top: 60px;

    .link {
      padding: 25px 20px;
      background-color: var(--defcl);
      margin-bottom: 22px;
      border-radius: 20px;
      border: 1px solid rgba(207, 207, 207, 0.11);
      display: flex;
      align-items: center;

      .arrow {
        margin-inline-start: auto;
        transform: scale(1.5);
      }
      .icon {
        transform: scale(1.3);
      }
      .texts {
        margin-inline-start: 25px;

        h2,
        p {
          margin: 0;
        }
        h2 {
          margin-bottom: 10px;
        }
      }
    }
  }
}

h3.how-it-works {
  font-size: 22px;
  margin-top: 60px;
  margin-bottom: 30px;
  font-weight: 700;
}

.shop-videos {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;

  .shop-video-container {
    img {
      width: 100%;
      border: 1px solid rgba(207, 207, 207, 0.11);
      border-radius: 10px;
      cursor: pointer;
      opacity: 1;
      transition: opacity 200ms;

      &:hover {
        opacity: 0.7;
      }
    }
    p {
      font-size: 13px;
      margin-top: 5px;
      margin-bottom: 0px;
    }
  }
}

.fullscreen-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;

  iframe {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 95%;
  }
}

@media only screen and (max-width: 621px) {
  .ShopSettingMain .link .arrow {
    display: none;
  }

  .shop-videos {
    grid-template-columns: 1fr;
  }

  .fullscreen-video iframe {
    top: var(--mobile-top);
  }
}
